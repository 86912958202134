<template>
  <div>
    <b-container class="vh-100 overflow-hidden" fluid>
        <div class="bg-white d-flex align-items-center justify-content-center h-100" v-if="loading">
            <b-spinner></b-spinner>
        </div>
        <b-row class="loginrow" v-else>
            <b-col class="logincol h-100">
                <b-img fluid class="logo mb-4" :src="require(`../assets/images/logomarca/logomarca-dev.png`)" v-if="isStandard()"></b-img>
                <b-img fluid class="logo mb-4" :src="`/images/logos/${url}-logomarca.png`" v-else></b-img>
                <!-- <b-img fluid class="logo mb-4" :src="require(`../assets/images/logomarca/logomarca.png`)"></b-img> -->
                <div class="recoveremailtxt" v-if="recoverOn" @click.prevent="back">Digite o E-mail de Recuperação</div>
                <b-alert variant="danger" class="w-100 mb-4 p-3 d-flex flex-row-reverse justify-content-between align-items-center" v-model="error.show" dismissible>
                    {{ error.msg }}
                </b-alert>
                <b-form class="form" @submit.prevent="login">
                    <div v-if="registerOn">
                        <b-form @submit.prevent="register">
                            <b-form-group >
                                <b-form-input class="shadow-none login" @input="setName" type="text" placeholder="Digite seu nome"></b-form-input>
                                <b-form-input class="shadow-none login" @input="setEmail" type="text" placeholder="Digite seu email"></b-form-input>
                                <b-form-input class="shadow-none password" @input="setPassword" type="password" placeholder="Digite uma senha"></b-form-input>
                            </b-form-group>
                            <div class="btnsdiv d-flex">
                                <b-button class="back" @click.prevent="back">
                                    <b-icon icon="chevron-left"></b-icon>
                                </b-button>
                                <div>
                                    <b-button class="entrar darkenOnHover" type="submit" style="padding: 6px 20px">{{btntxt}}</b-button>
                                </div>
                                <b-button class="back">
                                    <b-icon icon="chevron-left"></b-icon>
                                </b-button>
                            </div>
                        </b-form>
                    </div>
                    <div v-else-if="!recoverOn">
                        <b-form-group class="form" >
                            <b-form-input class="shadow-none login" @input="setEmail" type="text" placeholder="Login"></b-form-input>
                            <b-form-input class="shadow-none password" @input="setPassword" type="password" placeholder="Senha"></b-form-input>
                            <div class="d-flex justify-content-between">
                                <!-- <div><a class="recover" @click.prevent="registerForm">Cadastrar-Se</a></div> -->
                                <!-- <div><a class="recover" @click.prevent="recover">Recuperar Senha?</a></div>  -->
                            </div>
                        </b-form-group>
                        <div class="vstack gap-4">
                            <div class="btnsdiv justify-content-center">
                                <b-button class="entrar" type="submit">{{ btntxt }}</b-button>
                            </div>
                            <div class="hstack gap-3" v-if="document.location.host == 'gotalk.digital' || document.location.host == 'app.gotalk.digital' || document.location.host == 'app.gotalk.digital'">
                                <div class="border-bottom border-secondary w-100 mt-1"></div>
                                <div class="text-white">ou</div>
                                <div class="border-bottom border-secondary w-100 mt-1"></div>
                            </div>
                            <div class="d-flex justify-content-center" v-if="document.location.host == 'gotalk.digital' || document.location.host == 'app.gotalk.digital'">
                                <!-- <a class="rounded-pill px-4 py-2 text-white bg-primary darkenOnHover fw-semibold" href="https://notifiqueai.b2clogin.com/notifiqueai.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_teste&client_id=1814471a-86d4-430c-a88b-b07b3d56c150&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fgotalk.digital%2Flogin&scope=openid&response_type=id_token&prompt=login">Entrar com Azure</a> -->
                                <b-button class="rounded-pill px-4 py-2 darkenOnHover fw-semibold" variant="primary" v-b-modal.askEmail>Entrar com Azure</b-button>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <b-form-group>
                            <b-form-input class="shadow-none login" @input="setEmail" type="text" placeholder="E-mail"></b-form-input>
                        </b-form-group>
                        <div class="btnsdiv">
                            <b-button v-if="recoverOn" class="back" @click.prevent="back">
                                <b-icon icon="chevron-left"></b-icon>
                            </b-button>
                            <div>
                                <b-button class="entrar" type="submit" >{{btntxt}}</b-button>
                            </div>
                            <b-button v-if="recoverOn" class="back">
                                <b-icon icon="chevron-left"></b-icon>
                            </b-button>
                        </div>
                    </div>
                </b-form>
            </b-col>
            <!-- <b-col class="imgcol">
                <b-img fluid class="bgimg" src="https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Flogin_bg.png?alt=media&token=eefa7b0a-b9f2-4a19-8ceb-2af67a699359"></b-img>
            </b-col> -->
            <b-col class="imgcol2 p-0">
                <b-img fluid class="bgimg2 h-100 w-100" :src="require(`../assets/images/login/login-bg-dev.jpg`)"></b-img>
            </b-col>
        </b-row>
    </b-container>
    <b-modal id="askEmail" ref="askEmail" title="Entrar com Azure" centered header-class="py-0" hide-footer>
        <b-form @submit.prevent="loginMicrosoft" class="vstack gap-3">
            <b-form-group label="Digite seu email:" label-class="text-secondary">
                <b-form-input placeholder="Email" v-model="azureEmail" required/>
            </b-form-group>
            <b-button type="submit" class="align-self-start px-4 py-2 darkenOnHover fw-semibold" variant="primary">Continuar</b-button>
        </b-form>
    </b-modal>
  </div>
</template>

<script>
import api from '../services/apiService.js'
import utils from '../utils/utils'

export default {
    props: [
        'url'
    ],
    mounted: function() {
        if(this.$route.query && !(Object.keys(this.$route.query).length === 0 && this.$route.query.constructor === Object)) {
            this.queryManager(this.$route.query)
        }
    },
    data() {
        return {
            form: {},
            recoverOn:false,
            registerOn:false,
            btntxt:'Entrar',
            error: {show:false,msg:""},
            loading: false,
            azureEmail: '',
            document: document,
            envMode: process.env.NODE_ENV
        }
    },
    methods: {
        setName(value) {
            this.form.name = value
        },
        setEmail(value) {
            this.form.login = value
        },
        setPassword(value) {
            this.form.password = value
        },
        back() {
            this.registerOn = false
            this.recoverOn = false
            this.btntxt = 'Entrar'
        },
        recover() {
            this.btntxt = 'Enviar'
            this.recoverOn = true
        },
        registerForm() {
            this.btntxt = 'Cadastrar'
            this.registerOn = true
        },
        register() {
            const form = this.form
            console.log('form',form)
        },
        async login() {
            const form = this.form
            try {
                form.internalIP = await utils.getUserInternalIp()
            } catch(err) {
                console.error(err)
            }
            const resp = await api.authenticate(form)
            if(resp.statusCode == 409) {
                this.error.show = 5
                this.error.msg = "Email ou Senha inválida!"
            } else if(resp.statusCode == 410) {
                this.error.show = 5
                this.error.msg = "Canal bloqueado!"
            } else if(resp.statusCode == 200) {
                // console.log('resp',resp)
                this.authSuccess(resp)
            } else {
                console.log('error',resp.error,resp.statusCode)
                this.error.show = 5
                this.error.msg = "Ocorreu um erro, tente novamente mais tarde!"
            }
        },
        async getOperatorData(id) {
            const resp = await api.getOperatorData(id)
            if(!resp.operator && resp.statusCode!=200) {
                this.operator = {}
                return
            }
            resp.operator.privacy = resp.privacy
            return resp.operator
        },
        async queryManager(queryObj) {
            if(queryObj.logout) {
                switch(queryObj.logout) {
                    case 'outside_working_hours':
                        this.$emit('msg', {
                            text: "Você não tem permissão para acessar a plataforma neste horário!",
                            success: false
                        })
                        break
                }
            } else if(queryObj.stg) {
                const stg = decodeURIComponent(queryObj.stg)
                const decryptedText = this.$CryptoJS.AES.decrypt(stg, process.env.VUE_APP_CRYPTO_PASS).toString(this.$CryptoJS.enc.Utf8)
                const storageData = JSON.parse(decryptedText)

                if(storageData.user) {
                    if(storageData.token) {
                        localStorage.setItem('token', storageData.token)
                    }
                    
                    if(storageData.tokenTimestamp) {
                        localStorage.setItem('tokenTimestamp', storageData.tokenTimestamp)
                    }

                    if(storageData.operator) {
                        localStorage.setItem('operator', storageData.operator)
                    }

                    this.$emit('login', JSON.parse(storageData.user))
                    this.$emit('token', JSON.parse(storageData.token))

                    localStorage.setItem('user', storageData.user)
                }

                this.$router.replace('/')
            } else if (queryObj.state) {
                this.loading = true

                const resp = await api.getOperatorTokenAzure(queryObj.state)

                if (resp.statusCode === 200) {
                    this.authSuccess(resp)
                } else {
                    this.loading = false
                    this.error.show = 5
                    this.error.msg = "Sessão inválida!"
                }
            }
        },
        isStandard() {
            if(this.url == 'gotalk' || this.url.includes('localhost') || this.url == 'dev') {
                return true
            }
        },
        async authSuccess(resp) {
            if (resp.token) {
                resp.login = resp.token
                resp.login.user.channelId = resp.login.channelId
            }
            resp.login.user.roleId = resp.login.roleId
            resp.login.user.channelNumber = resp.login.channelNumber
            resp.login.user.urlStorage = resp.login.urlStorage
            resp.login.user.urlServer = resp.login.urlServer

            let storageToken = JSON.stringify(resp.login.token),
                storageTokenTimestamp = JSON.stringify(Date.now()),
                storageOperator,
                storageUser
            localStorage.setItem('token', storageToken)
            localStorage.setItem('tokenTimestamp', storageTokenTimestamp)

            if(resp.login.user.role == 'operator') {
                const operator = await this.getOperatorData(resp.login.roleId)
                resp.login.user.operator = operator
                if(operator) {
                    storageOperator = JSON.stringify(operator)
                    localStorage.setItem('operator', storageOperator)
                }
            }

            if(resp.login.user.role == 'channel_manager') {
                const respManager = await api.getManager(resp.login.roleId)
                if(respManager.statusCode < 400) {
                    resp.login.user.name = respManager.manager.manager.name
                }
            }

            this.$emit('login',resp.login.user)
            this.$emit('token',resp.login.token)

            storageUser = JSON.stringify(resp.login.user)
            localStorage.setItem('user', storageUser)

            if(resp.login.user.role != 'admin' && resp.login.urlServer && window.location.host.includes('gotalk')) {
                if(resp.login.urlServer != `${window.location.protocol}//${window.location.host}`) {
                    const storages = {
                        token: storageToken,
                        tokenTimestamp: storageTokenTimestamp,
                        operator: storageOperator,
                        user: storageUser
                    }
                    const encryptedText = this.$CryptoJS.AES.encrypt(JSON.stringify(storages), process.env.VUE_APP_CRYPTO_PASS).toString()
                    window.location.replace(`${resp.login.urlServer}/#/login?stg=${encodeURIComponent(encryptedText)}`)
                    // window.location.replace(`http://localhost:8080/#/login?stg=${encodeURIComponent(encryptedText)}`)
                } else
                    this.$router.replace({ path: '/' })
            } else {
                if(this.$route.query && !(Object.keys(this.$route.query).length === 0 && this.$route.query.constructor === Object) && this.$route.query.redirect)
                    this.$router.replace({ path: this.$route.query.redirect })
                else
                    this.$router.replace({ path: '/' })
            }
        },
        loginMicrosoft() {
            location.replace(`https://login.microsoftonline.com/04eca944-6c51-44b3-968f-d0b53acd3878/oauth2/authorize?client_id=d8c9c426-a4a8-45fa-9b40-e86048183b22&response_type=code&redirect_uri=https://gotalk.digital/webhooks/elianeauth&response_mode=fragment&scope=https://graph.microsoft.com/mail.read&state=${this.azureEmail}`)
        }
    },
}
</script>

<style scoped>
    .errmsg{
        color: #fff;
        margin-top: 5vh;
        padding: 1vh 1vw;
        border-radius: 5px;
        text-align: center;
        display: hidden;
    }
    .loginrow{
        height: 100vh;
        height: 100dvh;
    }
    .logincol{
        padding: 0 6.5vw;
        background-color: hsl(160.85deg 61.57% 44.9%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 0.25;
    }
    .login,.password{
        color: #ccc;
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding: 10px 0;
    }
    .login{
        border-bottom: .5px solid hsl(162, 100%, 93%);
    }
    .login:focus, .password:focus{
        background-color: transparent;
        color: #fff;
    }
    .login::placeholder, .password::placeholder{
        color: hsl(162, 100%, 93%) !important;
    }
    .recover{
        color: rgb(255, 255, 255);
        text-decoration: none;
        font-size: 12px;
        cursor: pointer;
    }
    .recover:hover, .keep:hover{
        color: #999;
    }
    .custom-control-label{
        padding-left: 1vw;
    }
    .entrar, .entrar:focus{
        display: flex;
        color: hsl(160.85deg 61.57% 44.9%);
        background-color: #fff;
        border-radius: 25px;
        border: none;
        padding: 6px 40px;
        font-size: 20px;
    }
    .btn:focus{
        box-shadow: none !important;
    }
    .imgcol{
        background-color: hsl(243deg 17% 45%);
        padding-right: 0;
        padding-top: 5vh;
    }
    /* .imgcol2{
        background-color: hsl(243deg 75% 15%);
    } */
    /* .bgimg{
        height: 95vh;
        float: right;
    } */
    /* .bgimg2{
        opacity: 0.5;
    } */
    .recoveremailtxt{
        text-align: center;
        margin-top: 5vh;
        color: #fff;
    }
    .btnsdiv{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5vh;
    }
    .back{
        display: flex;
        background-color: hsl(232deg 37% 62%);
        color: #fff;
        border-radius: 50%;
        border: none;
        font-size: 20px;
        padding: 8px 6px;
    }
    .back:last-child{
        visibility: hidden;
    }
    @media (max-width: 575.98px) {
        .logincol{
            flex-grow: 0.9;
        }
        .login{
            border-radius: 8px 8px 0 0;
            align-items: center;
        }
        .password{
            border-radius: 0 0 8px 8px;
        }
        .imgcol{
            display: none;
        }
        .loginrow {
            flex-direction: column;
            height: 111vh;
        }
        .imgcol2{
            display: none;
        }
    }
</style>